import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

interface WhatsAppPageProps {
  accessToken: string;
}

const WhatsAppPage: React.FC<WhatsAppPageProps> = ({ accessToken }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [qrCode, setQrCode] = useState<string | null>(null);

  const handleConnect = async () => {
    try {
      const response = await fetch(apiUrl+'/connect-whatsapp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ phoneNumber }),
      });

      if (response.ok) {
        const data = await response.json();
        setQrCode(apiUrl+"/qr/wa-qr-" + data.qr);
      } else {
        console.error('Failed to connect WhatsApp');
      }
    } catch (error) {
      console.error('Error connecting WhatsApp:', error);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-8 rounded shadow text-black">
      <h2 className="text-2xl mb-6">Let's connect your WhatsApp account</h2>
      <button
        onClick={handleConnect}
        className="w-full bg-blue-500 text-white p-2 rounded"
      >
        Connect WhatsApp
      </button>
      {qrCode && (
        <div className="mt-4">
          <img src={qrCode} alt="QR Code" />
        </div>
      )}
    </div>
  );
};

export default WhatsAppPage;